import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import TitledVerticalBoxes from '../components/TitledVerticalBoxes';
import CustomerCase from '../components/CustomerCase';
import DigitalTransformation from '../components/DigitalTransformation';
import VerticalBox from '../components/VerticalBox';
import IconText from '../components/IconText';
import Trumpf from '../images/trumpf.svg';
import B2BCycle1 from '../images/b2b-cycle-1.svg';
import B2BCycle11 from '../images/b2b-cycle-11.svg';
import B2BCycle2 from '../images/b2b-cycle-2.svg';
import B2BCycle3 from '../images/b2b-cycle-3.svg';
import B2BCycleTotal from '../images/b2b-cycle-total.png';

function CustomIconText({ title, iconId, content }) {
  return (
    <Col xl={6} sm={12} className="icon-text-col">
      <IconText title={title} content={content} iconId={iconId} iconSize={50} iconPosition="top" />
    </Col>
  );
}

CustomIconText.propTypes = {
  title: PropTypes.string,
  iconId: PropTypes.string,
  content: PropTypes.string,
};

function ArrowTitle({ title }) {
  return (
    <div className="arrow-title">
      <div>{title}</div>
    </div>
  );
}

ArrowTitle.propTypes = {
  title: PropTypes.string,
};

export default () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const { innerWidth } = window;
    if (innerWidth > 1200) {
      setIsMobile(false);
    } else if (innerWidth > 576) {
      setIsMobile(1);
    } else {
      setIsMobile(2);
    }
  }, []);
  return (
    <Layout current="solutions" subCurrent="b2b">
      <CommonPageBanner
        title="B2B行业解决方案"
        description="跨平台采集并整合各渠道营销数据，建立私域流量运营矩阵，完成获客、转化、裂变等营销目标，通过360度线索画像以及自动化线索培育,Convertlab荟聚帮助B2B企业提升不同获客渠道的转化ROI，且无缝衔接CRM等营销软件，打造企业营销闭环。"
        imgName="b2b"
      />
      <TitledVerticalBoxes title="B2B行业面临的营销挑战">
        <Col className="icon-text-col same" lg={8} md={12} xs={12}>
          <VerticalBox
            iconIndex={83}
            description="获客渠道分散"
            description2="渠道分散，数据不统一，无法有效管理，重复及无效数据混杂"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} xs={12}>
          <VerticalBox
            iconIndex={84}
            description="长尾线索严重浪费"
            description2="对短期低价值线索缺少线索持续沟通和培育，线索流失率高"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} xs={12}>
          <VerticalBox
            iconIndex={85}
            description="无法及时发现高意向线索"
            description2="无法根据实时客户行为洞察客户意向发现优质商机"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} xs={12}>
          <VerticalBox
            iconIndex={86}
            description="线索管理流程不清晰"
            description2="缺乏对MQL/SQL的定义和管理，导致市场销售协作效率低下"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} xs={12}>
          <VerticalBox
            iconIndex={87}
            description="线索缺乏维护及再利用"
            description2="大量线索沉寂流失，销售跟进后没有有效手段长期维护客户"
          />
        </Col>
        <Col className="icon-text-col same" lg={8} md={12} xs={12}>
          <VerticalBox
            iconIndex={88}
            description="渠道ROI难以评估"
            description2="无法追踪不同广告渠道带来的线索量及其转换情况"
          />
        </Col>
      </TitledVerticalBoxes>
      <DigitalTransformation title="Convertlab荟聚一体化B2B营销云：赋能客户全生命周期管理">
        {isMobile === 2 ? (
          <img className="b2b-cycle-total" src={B2BCycleTotal} alt="b2b-cycle-total" />
        ) : (
          <div className="b2b-cycle-rows">
            <Row className="title-arrows-row">
              <Col span={8} className="first">
                <ArrowTitle title="全渠道数字化集客" />
              </Col>
              <Col span={8} className="second">
                <ArrowTitle title="中心化线索管理与培育" />
              </Col>
              <Col span={8} className="third">
                <ArrowTitle title="市场销售协同" />
              </Col>
            </Row>
            <Row className="cycle-content-container">
              <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="first">
                <img src={isMobile ? B2BCycle11 : B2BCycle1} alt="b2b-cycle-1" />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="second">
                <img src={B2BCycle2} alt="b2b-cycle-2" />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="third">
                <img src={B2BCycle3} alt="b2b-cycle-3" />
              </Col>
            </Row>
            <Row>
              <div className="bottom-bar">数据中心化</div>
            </Row>
          </div>
        )}
      </DigitalTransformation>
      <TitledVerticalBoxes title="Convertlab荟聚可实现丰富的B2B数字营销场景" shadowed>
        <Col className="icon-text-col same" lg={6} md={12} xs={12}>
          <VerticalBox
            iconIndex={89}
            description="全渠道引流获客"
            description2="整合线上线下全渠道营销触点，将用户数据自动汇总到统一的客户档案"
          />
        </Col>
        <Col className="icon-text-col same" lg={6} md={12} xs={12}>
          <VerticalBox
            iconIndex={90}
            description="私域流量运营管理"
            description2="将用户统一至微信生态进行培育及再利用，实现低成本获客转化"
          />
        </Col>
        <Col className="icon-text-col same" lg={6} md={12} xs={12}>
          <VerticalBox
            iconIndex={91}
            description="线上线下活动管理"
            description2="全面掌握活动每个阶段的数据信息，提升管理效率，简化活动运营流程"
          />
        </Col>
        <Col className="icon-text-col same" lg={6} md={12} xs={12}>
          <VerticalBox
            iconIndex={92}
            description="完善用户画像"
            description2="打造用户的360全局画像，赋能精细运营及用户价值的深挖"
          />
        </Col>
        <Col className="icon-text-col same" lg={6} md={12} xs={12}>
          <VerticalBox
            iconIndex={93}
            description="市场销售协同"
            description2="根据业务规则，进行线索自动打分和线索智能分配，有效辅助商机跟进"
          />
        </Col>
        <Col className="icon-text-col same" lg={6} md={12} xs={12}>
          <VerticalBox
            iconIndex={94}
            description="内容营销管理"
            description2="提供从在线内容生产到内容流转的一站式服务，促进销售转化"
          />
        </Col>
        <Col className="icon-text-col same" lg={6} md={12} xs={12}>
          <VerticalBox
            iconIndex={95}
            description="自动化线索培育"
            description2="通过自动化流程提升线索转化率，个性化触达潜在客户，发现优质商机"
          />
        </Col>
        <Col className="icon-text-col same" lg={6} md={12} xs={12}>
          <VerticalBox
            iconIndex={96}
            description="渠道效果追踪"
            description2="追踪不同来源渠道带来的线索量及其转换情况，强化市场 ROI 报表能力"
          />
        </Col>
      </TitledVerticalBoxes>
      <CustomerCase
        title="B2B行业客户案例"
        icon={Trumpf}
        description="通快集团（Trumpf）创建于1923年，总部位于德国迪琴根，具有90多年的机床生产历史，在全球26个国家有70多个子公司，是全球制造技术领域的领导企业之一，在工业 4.0、机床、激光技术以及电子领域提供生产解决方案。自2000年开始在中国投资，先后在江苏太仓与广东东莞投资了四家企业，成为通快亚太区的生产、研发和服务中心，对通快在亚洲的销售、生产、服务与应用提供强有力的支持。"
        challenges={[
          {
            id: 'icon-1',
            iconIndex: 7,
            description: '获客渠道分散，用户在不同渠道多次交互后，无法统一数据整理及合并识别。',
          },
          {
            id: 'icon-2',
            iconIndex: 97,
            description: '除销售端用户画像缺失外，线索管理流程不清晰，跨部门协作效率低。',
          },
          {
            id: 'icon-3',
            iconIndex: 14,
            description: '销售互动方式单一，缺乏内容互动，无法了解客户后续主动行为偏好及需求。',
          },
        ]}
        solutions={[
          '聚合线上直播/线下活动/官网/微信等各渠道数据形成全域客户画像。基于数据洞察之上进行个性化精准触达，提升客户体验。',
          '将线索转indoor sales，再转销售的流程中，根据业务规则 & 线索质量打分，SDR/销售自动实时获得最新线索，有效促进商机跟进效果。',
          '提供从在线内容生产到内容流转的一站式服务，支持创建文档、视频、落地页等内容形式，提升品牌认知，促进销售转化。',
          '通过企微助手，销售除获得indoor sales分配线索 & 反馈线索转化情况外 ，同时得到市场内容赋能支撑，加强个性化客户沟通。',
        ]}
        effects={[
          {
            id: 'icon-1',
            iconId: 'iconeffect-14',
            content:
              '获客转化率提升：根据客户旅程设计全生命周期客户培育流程，以持续性流程（always on）自动且不间断实施线索孵化。',
          },
          {
            id: 'icon-2',
            iconId: 'iconeffect-1',
            content:
              '人员效能提升：将高质量线索，自动同步到企业微信，销售实时获得最新客户行为轨迹，完成『营』『销』一体化协同。',
          },
          {
            id: 'icon-3',
            iconId: 'iconeffect-15',
            content:
              '商机转化率提升：建立以商机为导向的内容营销体系，协助销售识别客户意图 & 影响力，提高沟通针对性，实现商机有效转化。',
          },
        ]}
      />
    </Layout>
  );
};
